.skeleton-root{
    height: 100%;
    overflow: hidden;
    position: relative;

    .skeleton-header{
        background-color: white;
        .info-popup-table {
            .MuiIconButton-root {
                display: block;
                margin-left: auto;
                padding: 5px 5px 0 0;
            }
        } 
        
        .country-and-data{
            width: 100%;
            display: flex;
            justify-content: space-between;

            .skeleton-text{
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.5rem;
                letter-spacing: 0.01071em;
                display: table-cell;
                vertical-align: inherit;
                text-align: left;
                padding: 16px;
                color: rgba(0, 0, 0, 0.87);
            }
        }
    }

    .skeleton-body{
        background-color: white;
        border-top: 1px solid #D3E6F3;

        .skeleton-div{
            display: flex;
            gap: 20px;
            margin: 20px ;
        }
    }

    .advertising{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        width: 70%;
        background-color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}




@media only screen and (min-device-width : 300px) and (max-device-width : 600px) {
    .info-popup-table {
        position: absolute;
        transform: translateY(-125%);
        margin-left: 10px;
        .MuiIconButton-root {
            display: block;
            margin-left: auto;
            padding: 5px 5px 0 0;
        }
    }
}
    
