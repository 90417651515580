.cards-column {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-right: 1rem;

    .card-roi-rev {
        flex: 2;
        .radar-chart-dashboard{
            box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            padding: 0 10px 0 5px;
            min-width: 275px;
            margin-top: 10px;

            .card-with-radar-chart{
                display: flex;
                justify-content: space-between;
                margin-left: 5px;

                .title-and-radio-container{
                    display: flex;
                    flex-direction: column;
                    flex-basis: 50%;
                    margin-top: 10px;

                    .radar-chart-title{
                        font-size: 16px;
                        
                       .MuiSvgIcon-root {
                            color: rgba(0, 0, 0, 0.5);
                            margin-bottom: -7px;
                        } 
                    }

                    .score-and-radioButtons{
                        display: flex;
                        flex-direction: column;
                        box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
                        border-radius: 4px;
                        width: 80%;
                        padding: 5px 10px 5px 15px;
                        margin: 5px 0 20px 0;

                        .MuiTypography-root{
                            font-size: 20px;
                        }

                        .radio-button-booking{
                            .MuiSvgIcon-root{
                                color: rgba(25, 118, 210, 1);
                            }
                            .MuiTypography-root{
                                font-size: 16px;
                            }
                        }
    
                        .radio-button-airDna{
                            .MuiSvgIcon-root{
                                color: rgba(102, 162, 222, 1);
                            }
                            .MuiTypography-root{
                                font-size: 16px;
                            }
                        }
    
                        .radio-button-hostelWorld{
                            .MuiSvgIcon-root{
                                color: rgba(167, 201, 234, 1);
                            }
                            .MuiTypography-root{
                                font-size: 16px;
                            }
                        }
                    }
                }

                .chart-container{
                    position: relative;
                    margin-top: 3%;
                    flex-basis: 50%;
                    max-height: 200px;
                }
            }  
        }
    }
} 