.login-screen{
    display: flex;
    width: 100vw;
    height: 100vh; 
    flex: 3;
    background-image: url('../../../assets/login-screen-background.svg');
    background-size: cover; 
    
    

    .login-budget-logo{
        flex: 2;
        position: relative;
        
        .logo{
            position: absolute;
            top: 50%;
            left: 47%;
            transform: translate(-50%, -50%);
        }
    }

    .login-form{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        

        .form{
            width: 70%;

            .title h1{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 150%;
                letter-spacing: 0.15px;
                color: #38474F;
                margin: 1rem 0 0 0;
            }
            .title p{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 150%;
                letter-spacing: 0.15px;
                color: #8E9498;
                margin: 0 0 2rem 0;
            }

            .MuiFormControl-root{
                width: 100%;
                .MuiTextField-root{
                    margin: 0.5rem 0 0.5rem 0;
                    background: #E8F0FE;
                    border: 1px solid #E4EAEC;
                    border-radius: 4px;


                    .MuiInputBase-root{
                        background: #E8F0FE;
                        .MuiOutlinedInput-input{
                            padding: 1rem 0.5rem 1rem 0.5rem;
                        }
                        .MuiOutlinedInput-notchedOutline{
                            border: none;
                        } 
                    }

                    .MuiInputBase-root:before {
                        border-bottom: transparent;
                    }
                } 
            }

            .checkbox-and-forgot-password{
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .MuiFormHelperText-root{
                    margin-top: 8px;
                }
                .MuiFormHelperText-root a{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 150%;
                    letter-spacing: 0.15px;
                    color: var(--primary-color-light);
                    text-decoration: none;
                }
            }

            .login-button{
                color: white;
                background-color: var(--primary-color-lighter);
                margin-top: 10%;
                text-transform: capitalize;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                letter-spacing: 0.46px;
            }

            .beta-sign{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: 0.15px;
            }
        }
    }
}

@media only screen and (min-device-width : 1000px) and (max-device-width : 1600px) {
    .login-screen{
        display: flex;
        width: 100vw;
        height: 100vh; 
        flex: 2;
        background-image: url('../../../assets/login-smalls-screen-background.svg');
        background-size: cover; 
        
        .login-budget-logo{
            flex: 1;
            position: relative;
            .logo{
                width: 100%;
                position: relative;
            }
            .logo img{
                width: 70%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .login-form{
            flex: 1;
            .form{
                width: 60%;
                margin-left: 30%;
            }
        }
    } 
}

@media only screen and (min-device-width : 750px) and (max-device-width : 1000px) {
    .login-screen{
        display: flex;
        flex-direction: column;
        background-image: url('../../../assets/login-responsive-screen-background.svg');
        background-size: cover; 
        
        .login-budget-logo{
            .logo{
                width: 100%;
                position: relative;
            }
            .logo img{
                width: 50%;
                position: absolute;
                left: 50%;
                transform: translate(-50%, -120%);
            }
        }

        .login-form{
            margin-bottom: 7rem;
            position: relative;
            .form{
                width: 60%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -60%);
            }
        }
    } 
}

@media only screen and (min-device-width : 320px) and (max-device-width : 750px) {
    .login-screen{
        display: flex;
        flex-direction: column;
        background-image: url('../../../assets/login-responsive-screen-background.svg');
        background-size: cover; 
        
        .login-budget-logo{
            .logo{
                width: 100%;
                position: relative;
            }
            .logo img{
                width: 60%;
                position: absolute;
                left: 55%;
                transform: translate(-50%, -120%);
            }
        }

        .login-form{
            margin-bottom: 7rem;
            position: relative;

            .form{
                width: 80%;
                position: absolute;
                left: 50%;
                transform: translate(-50%, -20%);

                .title h1{
                    font-size: 28px;
                }

                .title p{
                    font-size: 16px;
                }

                .MuiFormControl-root{
                    width: 100%;
                    .MuiTextField-root{
                        .MuiInputLabel-root{
                            font-size: 12px;
                        }
                        .MuiInputBase-root{
                            .MuiOutlinedInput-input{
                                padding: 0.5rem 0.5rem 1rem 0.5rem;
                            }
                        }
                    } 
                }
    
                .checkbox-and-forgot-password{
                    .MuiFormControlLabel-root{
                        .MuiCheckbox-root{
                            padding: 7px;
                        }
                        .MuiTypography-root{
                            font-size: 13px;
                        }
                    }
                    .MuiFormHelperText-root a{
                        font-size: 13px;
                    }
                }
    
                .login-button{
                    margin-top: 7%;
                    font-size: 17px;
                }

                .beta-sign{
                    font-size: 14px;
                }
            }
        }
    } 
}