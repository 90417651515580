.cards-column {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-right: 1rem;

    .card-roi-ml {
        flex: 2;
        .card-values-dashboard {
            display: flex;
            flex-direction: column;
            box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            padding: 0 10px 0 5px;
            min-width: 275px;
            margin-top: 10px;

            .titles-container{
                display: flex;
                justify-content: space-between;
                padding: 10px 5px 0 5px;

                .card-title{
                    font-size: 16px;
                    .MuiSvgIcon-root {
                        color: rgba(0, 0, 0, 0.5);
                        margin-bottom: -7px;
                    }
                }

                .dynamic-compset-title{
                    font-size: 20px;
                }

                .css-i4bv87-MuiSvgIcon-root{
                    color: rgba(0, 0, 0, 0.3);
                }
            }

            .values-container{
                display: flex;
                justify-content: space-around;
                padding: 10px 0 0 0;
            }

            
        }
    }
} 