.other-screen {
  display: flex;

  .scroll-container{
    flex: 1;
    max-height: 88vh;
    overflow: auto;
  }
    .cards-container {
      display: flex;
      gap: 2em;
      flex-direction: column;
      .card{
        margin: 0 1em;
      }
    }

  .leaflet-container{
    height: 100%;
    .leaflet-control-attribution{
      display: none;
    }
  }

}