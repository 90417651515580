.dashboard-360 {
    display: flex;
    flex-direction: row;
    
    .cards-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1.5;
        .cards-column {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-right: 1rem;
      
            .weather-card {
                min-height: 182px;
                flex: 1;
                box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
                border-radius: 4px;
            }
        }    
    }

    .map-container {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        flex: 3;
        
        .map {
            flex: 6;
        }
    }

    .roitels-map {
        flex-basis: 32%;
        height: 100%;
    }

    @media (max-width: 1400px) {
        .cards-container{
            flex: 1;
            flex-basis: 40%;
            flex-grow: 0;
            max-width: 40%;
        }
        .map-container{
            flex: 2;
            flex-basis: 60%;
            flex-grow: 0;
            max-width: 60%;
        }
    }
}
