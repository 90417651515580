.side-bar {
    display: flex;

    .logo {        
        width: 80%;
        transition: opacity 20ms ease-in;
        opacity: 1;
        margin: 5% 12% 0 12%;
    }

    .logo-container{
        padding: 0 10px;

        .smallLogo {        
        width: 100%;
        transition: opacity 20ms ease-in;
        opacity: 1;
        }
    }
    

    &.open > .MuiPaper-root {
        min-width: var(--sideBar-max-width);
        max-width: var(--sideBar-max-width);
    }
    .MuiPaper-root {
        min-width: var(--sideBar-min-width);
        max-width: var(--sideBar-min-width);
        overflow: hidden;
        background-color: var(--primary-color-light);
        color: var(--secondary-color);
    }
    svg {
        fill: white;        
    }

    .sidebar-footer {
        display: flex;
        margin: auto 8px 0;
    }

    

    .sidebar-list {
        //CSS para el nombre de usuario y Budgets (agrega Lucio)
        .userName{
            color: white;
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 0.5px;
            margin-left: 15px;
        }

        .budget{
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 0.5px;
            color: white;
            margin-left: 10px;
            width: 100%;
            justify-content: flex-start;
            text-transform: none;
            width: 180px;
            }

        .budget:focus{
            background: #1984BD;
            box-shadow: inset 0px 3px 4px 2px rgba(0, 0, 0, 0.13);           
        }
        
      
        //CSS para los botones Smart View y Budget (agrega Lucio)
        .item-link {            
            text-decoration: inherit;
        }
        
        //Selector property (agrega Lucio)

        .property-selector{
            border: 2px solid white;
            border-radius: 5px;
            max-width: 100%;
            width: 90%;
            margin: 18px auto 0;
            .labelSelector{
                background-color: var(--primary-color-light);
                color: white;
                padding: 0 5px 0 5px;
            }

            .optionSelector{
                color: white;
                font-size: 14px;                
            }

            .MuiInputBase-root:after{
                border-bottom: none;
            }

            .MuiInputBase-root:before{
                border-bottom: none;
            }
            .MuiOutlinedInput-notchedOutline{
                border:none;
            }
                       
        }
                
    
        .list-section {
            background: var(--primary-color-dark);
            &.active{
                background: var(--primary-color-darker);
                padding: 5px 0;
                transition: padding-bottom 0.2s ease-in-out;
            }
            box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.15);                  
            border-radius: 5px;
            margin: 1rem 0.5rem 1rem 0.5rem;                            
            color: white;

            .section-icon {
                min-width: 35px;
                color: white;
                margin-left: 5px;                
            }

            .MuiTypography-root{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.15px;                                   
            }

            .item-link {
                color: inherit;
                text-decoration: inherit;                
                
                .MuiListItemButton-root{
                    max-width: 100%;
                    width: 88%;
                    padding: 0 0 0 10px;
                    margin: 10px 10px 0 10px;
                    min-height: 21px;
                    margin-top: -5px;


                   
                    .MuiTypography-root{
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 150%;
                        letter-spacing: 0.15px;
                        // padding-left: 30px;                        
                    }
                }
                
                &.active > .MuiButtonBase-root{
                    background: #1984BD;
                    box-shadow: inset 0px 3px 4px 2px rgba(0, 0, 0, 0.13);
                    border-radius: 5px;
                }
            }
        }

        //CSS DrawerFooter (Help Center + CopyRight / Agrega Lucio)

        .drawerFooter{
            position: fixed;
            bottom: 0px;  
            justify-content: center;
            margin-left: 15px;                
                 

         .help-center{
            color: white;
            font-weight: 400;
            text-transform: capitalize;
            text-decoration: inherit;            
            letter-spacing: 1.5px;
             gap:8px;
             
        }
        .copyRight{
            color: white;
            font-weight: 400;
            text-transform: capitalize;
            text-decoration: inherit;
            font-size: 12px;                  
              
        }   
        }
        
    }
}
.sidebar-icon.MuiIconButton-root {
    z-index: 1201;
    position: fixed;
    bottom: 22px;
    left: 38px;
    background: white;
    color: var(--primary-color-light);
    width: 36px;
    height: 48px;
    border-radius: 20px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    &:hover {
        background-color: var(--primary-color-lighter);
        color: white;
    }

    &.open {
        left: 180px;
    }
}
