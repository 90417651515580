@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap');

/*CSS para los filters bars*/
.filters-bar {
    gap: 10px;
    padding: 5px 0 5px 0;
    .MuiAutocomplete-root {
        max-width: 220px;
        min-width: 210px;

        .MuiTextField-root {
            .MuiFilledInput-root{
                .MuiAutocomplete-endAdornment{
                    .MuiAutocomplete-clearIndicator{
                        display: none;
                    }
                }
            }
            .MuiFilledInput-root:before {
                border-bottom: transparent;
            }
            .MuiInputLabel-root {
                font-size: 14px;
                line-height: 1.5em;
            }
        }
    }

    &.mobile {
        z-index: 1500;
    }

    .top-bar-filter {
        .MuiInputBase-root {
            background: rgba(25, 118, 210, 0.05);
            border-radius: 4px 4px 0px 0px;
            border-bottom: 2px solid var(--primary-color-lighter);
            padding-top: 13px;
        }

        .MuiFilledInput-root:after {
            border-bottom: none;
        }

        .MuiInputBase-root:before {
            border-bottom: none;
        }
    }

    .button-filter {
        background-color: var(--primary-color-lighter);
        text-transform: none;
        letter-spacing: 2px;
        max-width: 200px;
        min-width: 190px;
    }
}

/*CSS para las cards de los popover*/
.budget-card-info {
    &.popover {
        width: 20%;
        .card-with-info {
            margin: 10px;
        }
        .text-card-info {
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    &.modal {
        .card-with-info {
            background: white;
            border-radius: 12px;
            padding: 1rem;
            transform: translate(12%, 100%);
            top: 50%;
            left: 50%;
            width: 80%;
        }
    }
    .card-with-info {
        .title-and-close-button {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .title-card-info {
                margin: 1rem 0 0.5rem 0;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }
}

.budget-screen {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 800px) {
        margin-top: var(--topbar-height);
    }
    @media screen and (min-width: 1200px) {
        height: calc(100vh - var(--topbar-height) - 35px);
    }

    .forecast-title{
        width: 99%;
        margin: 10px auto;
        height: 40px;
        background-color: #012073;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;

        .MuiTypography-root{
            color: #FFF;
            font-family: 'Space Grotesk', sans-serif;
            font-size: 24px;
            font-weight: 700;
            margin: auto 0 auto 10px;
        }

        .datadamus{
            color: rgba(255, 255, 255, 0.87);
            font-size: 11px;
            font-weight: 400;
            letter-spacing: 1.5px;
            margin-right: 10px;

            @media screen and (min-width: 300px) and (max-width: 600px) {
                font-size: 8px;
                margin: auto 10px;
            }
        }
    }

    .info-container {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        flex: 1;
        width: 100%;
        margin: auto;
        .cards-with-selectors {
            width: 100%;
            margin: 10px 0;

            .breadcrumbs-mobile{
                width: 100%;
                display: flex;
                justify-content: center;
                .MuiBreadcrumbs-root{
                    font-weight: 500;

                    .MuiBreadcrumbs-ol{
                        .MuiBreadcrumbs-separator{
                            margin-left: 2px;
                            margin-right: 2px;
                        }
                    }
                }
            }

            .cards-container {
                .cards-row {
                    /*CSS de las cards con los charts*/
                    .card {
                        // margin: 10px;
                        width: 96%;
                        border-radius: 4px;
                        border: 1px solid #DDE7EE;
                        margin: auto;

                        .MuiCard-root {
                            box-shadow: none;
                        }

                        .MuiCardContent-root {
                            flex-direction: column;

                            .MuiTypography-root {
                                align-self: baseline;
                                color: rgba(0, 0, 0, 0.5);
                                font-size: 18px;
                                line-height: 20px;
                            }
                        }

                        .card-content {
                            display: flex;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                            background: #ffffff;
                            border-radius: 4px;
                            padding: 20px 20px 5px 20px;

                            .title-and-info-popup {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;

                                .MuiTypography-root{
                                    color: rgba(0, 0, 0, 0.87);
                                    font-size: 16px;
                                    font-weight: 400;
                                }

                                .MuiIconButton-root {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }

            .selectors-container {
                justify-content: space-evenly;
                display: flex;
                padding: 1% 25% 1.5% 25%;
                max-height: 42px;

                .budget {
                    color: rgba(69, 215, 149, 1);
                    .MuiCheckbox-root {
                        color: rgba(69, 215, 149, 1);
                    }
                }

                .now {
                    color: rgba(0, 75, 255, 1);
                    .MuiCheckbox-root {
                        color: rgba(0, 75, 255, 1);
                    }
                }

                .togo {
                    color: rgba(207, 108, 255, 1);
                    .MuiCheckbox-root {
                        color: rgba(207, 108, 255, 1);
                    }
                }

                .lastyear {
                    color: rgba(39, 185, 245, 1);
                    .MuiCheckbox-root {
                        color: rgba(39, 185, 245, 1);
                    }
                }
            }
        }
    }

    .map-and-advertising-container {
        display: flex;
        overflow-y: auto;
        width: 99%;
        margin: auto;
        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }
        @media screen and (min-width: 1200px) {
            gap: 10px;
        }

        .world-map-and-buttons {
            display: flex;
            flex-direction: column;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #DDE7EE;
            justify-content: space-between;
            max-width: 100%;
            width: 50%;
            // flex: 2;
            margin: 0 10px;

            @media screen and (min-width: 300px) and (max-width: 1200px) {
                width: 98% !important;
                margin: 0 auto;
            }

            .map {
                // margin: 0.5rem;
                // flex: 2 1;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
            }
                
            .country-radio-buttons {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                margin-bottom: 5px;
                .MuiTypography-root {
                    font-size: 14px;
                }
            }
        }

        /*CSS de las tablas con los paises*/
        .table {
            // flex: 1;
            border-radius: 4px;
            overflow: auto;
            border: 1px solid #DDE7EE;
            max-width: 100%;
            width: 24.5%;

            @media screen and (min-width: 300px) and (max-width: 1200px) {
                width: 98% !important;
                margin: 0 auto;
            }

            .MuiTableContainer-root {
                box-shadow: none;
            }

            .country-table-row {
                &-cell {
                    display: inline-flex;
                    width: 100%;
                    align-items: center;
                    min-width: 3rem;
                    gap: 1rem;
                }
                &-flag {
                    max-height: 2rem;
                    display: flex;
                    align-items: center;
                    img {
                        max-height: 1.5rem;
                        border-radius: 4px;
                    }
                }
                &-labels {
                    display: flex;
                    flex-direction: column;
                    label {
                        font-weight: bold;
                    }
                }

                .country-table-row-labels span {
                    font-size: 12px;
                }
            }

            .country-table {
                .info-popup-table {
                    .MuiIconButton-root {
                        display: block;
                        margin-left: auto;
                        padding: 5px 5px 0 0;
                    }
                }
                @media only screen and (min-device-width : 300px) and (max-device-width : 600px) {
                    .info-popup-table {
                        position: absolute;
                        transform: translateY(-125%);
                        margin-left: 10px;
                        .MuiIconButton-root {
                            display: block;
                            margin-left: auto;
                            padding: 5px 5px 0 0;
                        }
                    }
                }
                
            }
        }
    }


    // @media only screen and (min-device-width : 600px) and (max-device-width : 1200px){
    //     .copyright-container{
    //         position: relative;
    //         p {
    //             position: absolute;
    //             top: 50%;
    //             left: 50%;
    //             transform: translate(-50%, -100%); 
    //         };

    //     }
    // }
    // @media only screen and (min-device-width : 320px) and (max-device-width : 600px){
    //     .copyright-container{
    //         display: none;

    //     }
    // }
}

.no-data-modal{
    .MuiBox-root{
        border-radius: 12px;
        background-color: white;
        max-width: 100%;
        width: 30%;
        padding: 20px 40px 35px;
        gap: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto; 
        margin-top: 18%; 

        .title-and-close-modal{
            display: flex;
            flex-direction: column;

            .MuiButtonBase-root{
                margin-left: auto;
            }
            .MuiTypography-root{
                color: #0058D6;
                text-align: center;
                font-family: "Space Grotesk";
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
}

.budget-mobile-filters-drawer {
    .MuiPaper-root,
    .MuiBackdrop-root {
        margin-top: calc(var(--topbar-height) - 12px);
    }
    .title {
        margin: 1.5rem 1rem 0.5rem 0.8rem;
        color: white;
    }
    .subtitle {
        max-width: 100%;
        width: 85%;
        display: flex;
        margin: 18px auto 5px auto;
        font-size: 1.2rem;
        align-items: center;
        gap: 0.3rem;
        color: white;

        .button-filter-mobile {
            text-transform: capitalize;
            margin: 0.5rem 0 0.5rem 1.5rem;
            border-radius: 4px;        
            background-color: #0058D6;
            box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.15);
            color: white;
            width: 100%;
        }

        .button-filter-mobile:focus {
            margin: 0.5rem 0 0.5rem 1.5rem;
            border-radius: 4px;        
            background-color: #0058D6;
            box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.15);
            box-shadow: inset 0px 3px 4px 2px rgba(0, 0, 0, 0.13);
            color: white;
            width: 100%;
        }
    }
    .divider {
        margin: 0 2rem 0 0.8rem;
        background-color: white;
        color: white;
    }
    
    
    .MuiPaper-root {
        background: var(--primary-color-light);
        max-width: 100%;
        width: 70%;
    }
    .filter-accordion {
        max-width: 100%;
        width: 85%;
        margin: 1rem;
        border-radius: 4px;        
        background-color: #0058D6;
        box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.15);
        color: white;
        &.MuiAccordion-root {
            margin: 0.5rem auto;
        }
        &.MuiAccordion-root.Mui-expanded {
            margin: 0.5rem auto;
        }
        .MuiAccordionSummary-root {
            padding: 0;
            min-height: 40px;
            .MuiAccordionSummary-content {
                margin: 0;
            }
        }
        .MuiAccordionSummary-expandIconWrapper {
            order: -1;
            color: white;
            margin: 0 5px;
        }
        .MuiAccordionDetails-root {
            padding: 0;
            margin-left: 18px;
        }

        .activar{
            background-color: #1984BD;
            margin: 0 15px 5px 0;
            box-shadow: inset 0px 3px 4px 2px rgba(0, 0, 0, 0.13);
            border-radius: 4px;
        }
    }

    .copyright-mobile{
        color: white;
        font-size: 11px;
        width: 100%;
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translate(-43%, 50%);
    }
}

@media screen and (min-width: 300px) and (max-width: 340px) {
    .map-and-advertising-container{
        .world-map-and-buttons{
            .MuiFormGroup-root{
                .MuiFormControlLabel-root{
                    padding-left: 10%;
                    margin-right: 0;
                }
            }
        }
    }

    .table{
        .css-rqglhn-MuiTable-root{            
            .MuiTableBody-root{
                .MuiTableCell-root{
                    .country-table-row-labels{
                        padding-left: 20px;
                    }
                }
            }
        }
    }
}