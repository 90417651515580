.resume-row {
    display: flex;
    gap: 1rem;
    max-height: 181px;

    .line-chart-dashboard  {
    width: 100%;
    box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    border-left: 6px solid rgba(6, 236, 94, 0.16);
    min-width: 275px;
    max-height: 195px;
    padding-bottom: 10px;

        .card-with-line-chart{
            display: flex;
            justify-content: space-between;
            margin-left: 10px;

            .line-chart-title{
                height: 35px;
                margin-top: 10px;
                .MuiTypography-root{
                    font-size: 16px;
                    line-height: 1;
                } 
            }

            .line-chart-value{
                height: 80px;
                .MuiTypography-root{
                    font-weight: 600;
                    font-size: 23px;
                    color: rgba(0, 0, 0, 0.5);
                }
            }

            .line-chart-success-chip{
                margin-top: 20px;
                .MuiChip-root {
                    height: 25px;
                    background-color: rgba(6, 236, 94, 0.16);

                    .MuiChip-label {
                        font-size: 12px;
                    }
                }
            }

            .chart-line-data1{
                padding: 10px 0 5px 0;
                margin-left: 68%;
                .MuiTypography-root{
                    font-size: 13px;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .chart-line{
                position: relative;
                margin: auto;
                width: 90%;
                height: 77px;
            }

            .chart-line-data2{
                display: flex;
                flex-direction: column;
                padding: 10px 0 0 0;
                .MuiTypography-root{
                    font-size: 13px;
                    font-weight: 600;
                    margin: 0;
                    line-height: normal;
                }
            }
        }
    }
}

