// .revenue-screen {
//     display: flex;
//     flex-wrap: wrap;
//     .data-container {
//         flex-basis: 68%;
//         max-height: calc(100% - 94px);
//         display: flex;
//         flex-direction: column;
//         .cards-container {
//             .resume-row {
//                 display: flex;
//                 gap: 2em;
//                 padding-right: 1rem;
//                 .MuiCard-root {
//                     flex: 1;
//                     min-height: 15rem;
//                 }
//             }
//             .card {
//                 margin: 0 1em;
//             }
//         }
//         .charts-container {
//             margin-top: 1rem;
//             margin-right: 1rem;
//             flex: 1;
//             .chart {

//                 height: 100%;
//             }
//         }
//     }
//     .roitels-map {
//         flex-basis: 32%;
//         height: calc(100vh - 96px);
//     }
// }

// .mapboxgl-popup {
//     max-width: 400px;
//     font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
// }

.revenue-screen {
    display: flex;
    flex-direction: row;
    .card-container {
        flex-basis: 60%;
        max-height: calc(100% - 94px);
        display: flex;
        flex-direction: column;

        .resume-row {
            display: flex;
            gap: 0.5rem;
            width: 98.5%;
            
            .MuiCard-root {
                width: 100%;
                box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
                border-radius: 4px;
                border-left: 6px solid rgba(6, 236, 94, 0.16);

                
                .line-chart-title{
                   .MuiTypography-root{
                        font-size: 16px;
                        line-height: 1;
                    } 
                }

                .line-chart-value{
                    .MuiTypography-root{
                        font-weight: 600;
                        font-size: 23px;
                        color: rgba(0, 0, 0, 0.5);
                    }
                }

                .line-chart-success-chip{
                    margin-top: 20px;
                    .MuiChip-root {
                        height: 25px;
                        background-color: rgba(6, 236, 94, 0.16);

                        .MuiChip-label {
                            font-size: 12px;
                        }
                    }
                }

            }
        }
        
        .charts-container {
            margin-top: 1rem;
            margin-right: 1rem;
            flex: 1;
            .MuiCard-root{
                box-shadow: none;
            }
            .chart {
                height: 100%;
            }
        }
    }

    
        .roitels-map {
            flex-basis: 40%;
            height: calc(100vh - 96px);
        } 
    
    
}

.mapboxgl-popup {
    max-width: 400px;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
