.topbar {
    &.desktop {
        &.MuiPaper-root {
            width: calc(100% - var(--sideBar-min-width));
        }

        &.isSideBarOpen {
            width: calc(100% - var(--sideBar-max-width));
        }
    }
    flex-direction: row;
    display: flex; 
    background-color: unset;
    box-shadow: none !important;
    border-bottom: 1px solid #E6EEFB !important;

    .toolbar {
        background: white;
        color: black;
        display: flex;
        width: 100%;

        .select-lenguage{
            margin-left: 40%;
            .MuiFormControl-root {
                background-color: #F5F5F5;
                border-radius: 20px;
                width: 150px;

                .MuiInputBase-root{
                    color: var(--primary-color-lighter);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    padding-left: 25px;

                    .MuiSvgIcon-root{
                        color: var(--primary-color-lighter);
                    }
                }
            }
            .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
                // border: 2px solid var(--primary-color-light);
                border: none;
            }
            .MuiFormControl-root:hover .MuiOutlinedInput-notchedOutline {
                // border: 2px solid var(--primary-color-light);
                border: none;
            }
        }

        @media only screen and (min-device-width : 320px) and (max-device-width : 600px){
            .select-lenguage{
                display: none;
            }
            
        }

        .avatar-button {
            margin: 0;
            .avatar {
                background-color: white;
                color: var(--primary-color);
                border: 1px solid var(--primary-color);
            }
        }
        .fixed-to-right {
            margin-left: auto;
        }

        .logoMobile{
            width: 40px;
            height: 40px;
            margin-left: 45%;
        }
    }

    

    
    .open-icon {
        margin-right: 5em;
        padding: 8px 8px 8px 0;
    }
    .search-input {
        margin-left: auto;
        .MuiOutlinedInput-notchedOutline {
            border-radius: 30px;
            background: rgba(10, 10, 10, 0.1);
        }
        .MuiInputBase-input {
            padding: 8px 8px 8px 0;
        }
    }
    .notifications-badge {
        margin: 8px 8px 0 0;

        .MuiIconButton-root > .MuiIcon-root {
            color: var(--color-gray-dark);
            font-size: 30px;
        }
        .MuiBadge-badge {
            top: 12px;
            right: 12px;
            background-color: var(--color-orange);
        }
    }
}



@media only screen and (min-device-width : 600px) and (max-device-width : 1920px){
    .user-menu{
        .MuiPopover-paper{
            .MuiMenu-list{
                .select-lenguage-mobile{
                    .MuiButtonGroup-root{
                        display: none;
                    }
                }
            }
        }
    }
}


.select-lenguage-mobile{
    margin-left: 7%;
}