.App {
    text-align: center;
}

.fixed-height {
    height: calc(100vh - var(--topbar-height) - 35px);
}

:root {
    --sideBar-max-width: 200px;
    --sideBar-min-width: 55px;
    --topbar-height: 64px;

    --primary-color-lighter: #0058D6;
    --primary-color-light: #012073;
    --primary-color: #00a0e3;
    --primary-color-dark: rgba(25, 132, 189, 0.3);
    --primary-color-darker: rgba(25, 132, 189, 0.65);

    --secondary-color: #0be3ad;

    --color-gray: #F7F9FC;
    --color-gray-dark: #bbbbbb;
    --color-gray-darker: #aaaaaa;

    --color-orange: #ff7a00;

    --secondary-color-dark: #0f9675;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.main-content {
    padding: 12px 8px 8px 8px;
    background-color: var(--color-gray);
    min-height: calc(100vh - var(--topbar-height));

    &.desktop {
        margin-left: var(--sideBar-min-width);
        margin-top: 64px;
    }

    &.open-sidebar {
        margin-left: var(--sideBar-max-width);
    }
}

.filters-bar {

    display: flex;
    z-index: 1100;
    position: fixed;
    gap: 10px;
    padding: 0 0 0.5rem 0;
    top: 7px;
    &.desktop {
        min-width: 450px;
    }
    .MuiAutocomplete-root {
        max-width: 180px;
        min-width: 144px;

        .MuiTextField-root {
            .MuiInputLabel-root {
                font-size: 14px;
                line-height: 1.5em;
            }
        }
    }

    .top-bar-filter {
        .MuiInputBase-root {
            background: rgba(25, 118, 210, 0.05);
            border-radius: 4px 4px 0px 0px;
            border-bottom: 2px solid var(--primary-color-light);
        }

        .MuiFilledInput-root:after {
            border-bottom: none;
        }

        .MuiInputBase-root:before {
            border-bottom: none;
        }
    }

    .button-filter {
        background-color: var(--primary-color-light);
        text-transform: none;
    }
}
